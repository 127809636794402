<template>
    <div></div>
</template>
<script>
    import vueCookie from 'vue-cookie';
    export default {
        mounted() {
            vueCookie.set('loggedIn', false, 1);
            this.$router.push('/');
        }
    }
</script>
